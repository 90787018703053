.widgets{
    width: 100%;
    position: fixed;
    right: 20px;
    bottom:15px;
    height: 60px;
    z-index: 1000;
    .container{
        position: relative;
        width: 100%;
    }
}
.ss-icon {
    width: 60px; 
    height: 60px; 
    border-radius: 50%;
    background: var(--mainColor);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    &.clicked #center-bar{
      background: #000;
      outline: none;
    }
    #center-bar{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: var(--mainColor);
        position: relative;
        transition: .3s;
        z-index: 10;
        &:hover, &:focus {
            background: #333;
          }
          &:hover { 
            cursor: pointer;
          }
    }
    a { 
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: var(--mainColor);
        display:flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        transition: .4s;
        z-index: 9;
        &.whatsapp{
            background-color: #31D84F;
        }
        &.telegram{
            background-color: #23A9EA;
        }

        &:hover, &:focus {
        background-color: #000;
        }
    }
    
    &.clicked a:nth-child(2) { 
      transform:  translateY(-60px) ; 
    }
    &.clicked a:nth-child(3) { 
      transform:  translateY(-110px) ; 
    }
    &.clicked a:nth-child(4) { 
      transform:  translateY(-160px) ; 
    }
  }

  @media(max-width: 1400px){
    .widgets{

    
        .container{
            position: initial;
        }
    }
    .ss-icon {
        right: -2px;
    }
}

@media(max-width: 767px){
    .widgets{
        bottom: 70px;
    }
}